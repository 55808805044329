<template>
  <div class="home">
    <img alt="INFOCAFE" src="../assets/logo.png" width="20%">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
}
</script>


<style>
  .home {
      display: flex;
      align-items: center;
      justify-content: center;
  }
</style>